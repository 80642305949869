import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo.js"
import Link from "gatsby-link"
import Img from "gatsby-image"
import {graphql,useStaticQuery} from "gatsby"

const IndexPage = () => {

  const query = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "images/code.jpg" }) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        fluid{
          ...GatsbyImageSharpFluid

        }
      }
    }
  }
`)



  return ( 
      <Layout>
       <SEO/>
        <h1>Can Anyone Learn to Code?</h1>
        <Img  fluid={query.file.childImageSharp.fluid} className="codeImage mb-5" alt="Code"/>
        <p>The answer is <strong>YES!</strong> Coding is a tool that anyone can learn how to utilize for their own goals. </p>
        <p>Please use all of the resources Anonycoder has to become the programmer you want to be</p>
        
        <h3>Check out the latest posts <Link to="/blog">here</Link></h3>
        </Layout>

   );
}
 
export default IndexPage;
